import local from '@/utils/local'
import { GetProfileReq } from '@/api/user.js'
import { Dialog } from 'quasar'

export default async function (val) {
  const token = local.get('token') || local.getLocal('token')
  if (!!token) {
    await GetProfileReq().then(res => {
      let is_profile_completed = res.data.is_profile_completed
      local.set('tel', res.data.phone_number)
      if (is_profile_completed) {
        local.set('first_name', res.data.first_name)
        local.set('last_name', res.data.last_name)
        local.set('email', res.data.email)
        local.set('country_code', res.data.country_code)
        if (val)
          window.location.href =
            window.location.href.split(':')[0] +
            '://' +
            window.location.host +
            val
        // router.push(val)
      } else {
        Dialog.create({
          message: 'Your profile is not completed,Please completed',
          cancel: true,
        })
          .onOk(() => {
            window.location.href =
              window.location.href.split(':')[0] +
              '://' +
              window.location.host +
              `/transfer?redirect=${val}`
            // router.push(`/transfer?redirect=${val}`)
          })
          .onCancel(() => {
            // on cancel
          })
      }
    })
  }
}
